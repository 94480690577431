@import '../../../or_base/scss/theme-bootstrap';

.hero-carousel-banner-formatter {
  position: relative;
  &__header {
    padding: 1em;
    text-align: center;
    .hero-carousel-banner-formatter__header-title {
      @include font--heading;
      margin: 0;
      letter-spacing: 0;
    }
    .hero-carousel-banner-formatter__header-subtitle {
      @include font--text;
      line-height: 1;
      margin: 0;
      @include breakpoint($medium-up) {
        letter-spacing: 0;
      }
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
  &__header-size--small {
    .hero-carousel-banner-formatter__header-title {
      font-size: 18px;
    }
    .hero-carousel-banner-formatter__header-subtitle {
      font-size: 16px;
    }
  }
  &__header-size--medium {
    .hero-carousel-banner-formatter__header-title {
      font-size: 24px;
      @include breakpoint($medium-up) {
        font-size: 32px;
      }
      @include breakpoint($large-up) {
        font-size: 40px;
      }
    }
    .hero-carousel-banner-formatter__header-subtitle {
      font-size: 18px;
      @include breakpoint($medium-up) {
        font-size: 22px;
      }
    }
  }
  &__header-size--large {
    .hero-carousel-banner-formatter__header-title {
      font-size: 28px;
      @include breakpoint($medium-up) {
        font-size: 60px;
      }
    }
    .hero-carousel-banner-formatter__header-subtitle {
      font-size: 22px;
      @include breakpoint($medium-up) {
        font-size: 32px;
      }
    }
  }
  .carousel-controls .slick-arrow {
    background-color: transparent;
    background-image: url('/media/export/cms/global/carousel.arrows.png');
    background-repeat: no-repeat;
    height: 55px;
    margin: -27px 0 0 0;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      background-position: right 10px;
      right: 20px;
    }
  }
  .hero-carousel {
    .slick-dots {
      background-color: none;
      border-bottom: none;
      bottom: 7px;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      @include breakpoint($medium-up) {
        bottom: 0;
        padding: 10px 0 15px;
      }
      li {
        @include font--subheading;
        border-bottom: none;
        color: $color-gray;
        font-family: $font--futura-demi;
        font-size: 15px;
        height: auto;
        margin: 0;
        padding: 0 5px;
        width: auto;
        @include breakpoint($landscape-up) {
          border-bottom: 1px solid $color-gray;
          padding: 0 15px 8px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &.slick-active,
          &:hover {
            border-bottom: 3px solid $color-black;
            color: $color-drak-black;
            padding-bottom: 6px;
          }
        }
        &.slick-active {
          button {
            &:before {
              background-color: $color-gray;
              color: $color-gray;
            }
          }
        }
      }
    }
    &-overlay {
      .slick-dots {
        @include breakpoint($medium-up) {
          background-color: $transparent--white;
        }
      }
    }
  }
}
